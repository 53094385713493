<template>
  <div>
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="10">
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['0']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['0']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ p_productData['data']['0']['1']['lang'][StoreLang] }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['2'] && p_productData['data']['0']['2'].val">
              {{ p_productData['data']['0']['2'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['3']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['3']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['3']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['4']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['4'] && p_productData['data']['0']['4'].val">
              {{ p_productData['data']['0']['4'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['5']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['5'] && p_productData['data']['0']['5'].lang && p_productData['data']['0']['5'].lang[StoreLang]">
              {{ p_productData['data']['0']['5'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['5'] && p_productData['data']['0']['5'].val">
              {{ p_productData['data']['0']['5'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['6']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['6'] && p_productData['data']['0']['6'].lang && p_productData['data']['0']['6'].lang[StoreLang]">
              {{ p_productData['data']['0']['6'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['6'] && p_productData['data']['0']['6'].val">
              {{ p_productData['data']['0']['6'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['7']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['7'] && p_productData['data']['0']['7'].lang && p_productData['data']['0']['7'].lang[StoreLang]">
              {{ p_productData['data']['0']['7'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['7'] && p_productData['data']['0']['7'].val">
              {{ p_productData['data']['0']['7'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['8']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['8'] && p_productData['data']['0']['8'].lang && p_productData['data']['0']['8'].lang[StoreLang]">
              {{ p_productData['data']['0']['8'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['8'] && p_productData['data']['0']['8'].val">
              {{ p_productData['data']['0']['8'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['9']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['9'] && p_productData['data']['0']['9'].lang && p_productData['data']['0']['9'].lang[StoreLang]">
              {{ p_productData['data']['0']['9'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['9'] && p_productData['data']['0']['9'].val">
              {{ p_productData['data']['0']['9'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['10']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['10'] && p_productData['data']['0']['10'].lang && p_productData['data']['0']['10'].lang[StoreLang]">
              {{ p_productData['data']['0']['10'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['10'] && p_productData['data']['0']['10'].val">
              {{ p_productData['data']['0']['10'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['11']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['11'] && p_productData['data']['0']['11'].lang && p_productData['data']['0']['11'].lang[StoreLang]">
              {{ p_productData['data']['0']['11'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['11'] && p_productData['data']['0']['11'].val">
              {{ p_productData['data']['0']['11'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['12'] && p_productData['data']['0']['12'].lang && p_productData['data']['0']['12'].lang[StoreLang]">
              {{ p_productData['data']['0']['12'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['12'] && p_productData['data']['0']['12'].val">
              {{ p_productData['data']['0']['12'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['13']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['13'] && p_productData['data']['0']['13'].lang && p_productData['data']['0']['13'].lang[StoreLang]">
              {{ p_productData['data']['0']['13'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['13'] && p_productData['data']['0']['13'].val">
              {{ p_productData['data']['0']['13'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['14']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['14'] && p_productData['data']['0']['14'].lang && p_productData['data']['0']['14'].lang[StoreLang]">
              {{ p_productData['data']['0']['14'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['14'] && p_productData['data']['0']['14'].val">
              {{ p_productData['data']['0']['14'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['15']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['15'] && p_productData['data']['0']['15'].lang && p_productData['data']['0']['15'].lang[StoreLang]">
              {{ p_productData['data']['0']['15'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['15'] && p_productData['data']['0']['15'].val">
              {{ p_productData['data']['0']['15'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['16']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['16']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['16']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['17']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['17']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['17']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['18']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['18']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['18']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['19']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['19']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['19']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['20']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['20']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['20']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['21']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['21']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['21']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['22']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['22']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['22']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['23']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['23']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['23']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['24']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_productData['data']['0']['24']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['24']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['28']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['28'] && p_productData['data']['0']['28'].lang && p_productData['data']['0']['28'].lang[StoreLang]">
              {{ p_productData['data']['0']['28'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['28'] && p_productData['data']['0']['28'].val">
              {{ p_productData['data']['0']['28'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['29']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['29'] && p_productData['data']['0']['29'].lang && p_productData['data']['0']['29'].lang[StoreLang]">
              {{ p_productData['data']['0']['29'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['29'] && p_productData['data']['0']['29'].val">
              {{ p_productData['data']['0']['29'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['30']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['30'] && p_productData['data']['0']['30'].lang && p_productData['data']['0']['30'].lang[StoreLang]">
              {{ p_productData['data']['0']['30'].lang[StoreLang] }}
            </template>
            <template v-else-if="p_productData['data']['0']['30'] && p_productData['data']['0']['30'].val">
              {{ p_productData['data']['0']['30'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductWdm['0']['parameters']['25']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_productData['data']['0']['25'] && p_productData['data']['0']['25'].list.length > 0">
              <b-row v-for="(currency_amount_list, currency_amount_index) in p_productData['data']['0']['25'].list">
                <b-col sm="12" lg="12">
                    <template v-if="currency_amount_list['27'] && currency_amount_list['27'].lang && currency_amount_list['27'].lang[StoreLang]">
                      {{ currency_amount_list['27'].lang[StoreLang] }}
                    </template>
                    <template v-else-if="currency_amount_list['27'] && currency_amount_list['27'].val">
                      {{ currency_amount_list['27'].val }}
                    </template>
                    {{ f_getOptionLabel(currency_amount_list['26']['val']['value'], d_userShopcartBundleProductWdm['0']['parameters']['25']['parameters']['26']['options']) }}
                </b-col>
              </b-row>
            </template>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="1"></b-col>
    </b-row>
  
  </div>
</template>

<script>
import { data as usr_shpcrt_bund_pro } from '@/options/usr_shpcrt_bund_pro';
import { mapGetters } from 'vuex';
export default {
  name: 'ShopcardBundleProductShow',
  components: {},
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreDevice: 'StoreDevice',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreShopCartOrderList: 'StoreShopCartOrderList',
  }),
  props: {
    p_productData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_userShopcartBundleProductWdm: usr_shpcrt_bund_pro,
    }
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_calculateCurrencyAmountItemStyle: function (currency_amount_index) {
      let style = 'margin: 3px;';
      if (currency_amount_index % 2 === 0) {
        style += 'background-color: #99e4e8';
      } else {
        style += 'background-color: #99e4e8';
        // style += 'background-color: white;';
      }
      return style;
    },
    f_getOptionLabel: function (option_value, options) {
      // console.log('option_value : ', option_value);
      // console.log('options : ', options);
      let option_label = '';
      for (let i in options) {
        if (options[i].value === option_value) {
          option_label = options[i].translation[this.StoreLang];
          break;
        }
      }
      return option_label;
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

