import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  bundle_and_product_list_active (query, data) {
    let route = '';
    if (query) {
      route = 'v1/bundle/and/product/list/active?' + query;
    } else {
      route = 'v1/bundle/and/product/list/active';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  bundle_details (query, data) {
    let route = '';
    if (query) {
      route = 'v1/bundle/details?' + query;
    } else {
      route = 'v1/bundle/details';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
};
