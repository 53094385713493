<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="12">
          <b-row>
            <b-col cols="4">
              <strong>
                <i class="fa fa-check-square-o"></i>
              </strong>
              {{ StoreLangTranslation.data['support_operation_name'][StoreLang] }}
            </b-col>
            <b-col cols="8">
              <template v-if="p_supportData.edit_eligible">
                <b-form-textarea v-model="p_supportData.operation_name" rows="5" :placeholder="'maksimum ' + p_supportData.maximum_characters + ' karakter'"></b-form-textarea>
              </template>
              <template v-else>
                {{ p_supportData.operation_name }}
              </template>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="4">
              <strong>
                <i class="fa fa-check-square-o"></i>
              </strong>
              {{ StoreLangTranslation.data['support_operation_note'][StoreLang] }}
            </b-col>
            <b-col cols="8">
              <template v-if="p_supportData.edit_eligible">
                <b-form-textarea v-model="p_supportData.operation_note" rows="10" :placeholder="'maksimum ' + p_supportData.maximum_characters + ' karakter'"></b-form-textarea>
              </template>
              <template v-else>
                {{ p_supportData.operation_note }}
              </template>
            </b-col>
          </b-row>
          <hr>
          <b-row v-if="!p_supportData.edit_eligible">
            <b-col cols="4">
              <strong>
                <i class="fa fa-check-square-o"></i>
              </strong>
              {{ StoreLangTranslation.data['support_operation_note_additional'][StoreLang] }}
            </b-col>
            <b-col cols="8">
              <b-form-textarea v-model="p_supportData.operation_note_additional" rows="10" :placeholder="'maksimum ' + p_supportData.maximum_characters + ' karakter'"></b-form-textarea>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
            </b-col>
            <b-col cols="8" style="text-align: right;">
              <b-button variant="white" @click="f_userSupportNew()" size="sm">
                  <i class="fa fa-user-circle"></i>
                  {{ StoreLangTranslation.data['save'][StoreLang] }}
                </b-button>
            </b-col>
          </b-row>
          
          <!--
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-search"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">
              <b-input-group-append>
                <b-button variant="info">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { data as system_menu_list } from '@/options/system_menu_list';
import UserSupportService from '@/services/support';
import { mapGetters } from 'vuex';
export default {
  name: 'SupportScreen',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  props: {
    p_supportData: {
      type: Object,
      required: true
    },
    f_userSupportList: {
      type: Function,
      required: false
    }
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    //
  },
  methods: {
    f_getRoutePath: function (target_route_name_en) {
      let route_path = '';
      for (let i in system_menu_list) {
        if (system_menu_list[i].route_name.en == target_route_name_en) {
          route_path = system_menu_list[i].route_path[this.StoreLang];
        }
        if (!route_path) {
          if (system_menu_list[i].list && system_menu_list[i].list.length) {
            for (let k in system_menu_list[i].list) {
              if (system_menu_list[i].list[k].route_name.en == target_route_name_en) {
                route_path = system_menu_list[i].list[k].route_path[this.StoreLang];
                break;
              }
            }
          }
        }
        if (route_path) {
          break;
        }
      }
      return route_path;
    },
    f_goCustomerService: function () {
      this.$router.push({ path: this.f_getRoutePath('customer_service'), query: {} });
    },
    f_userSupportNew: function () {
      let query = 'user_client_id=' + this.d_user.username;
      if (this.p_supportData.user_shop_bundle_client_id) {
        query += '&user_shop_bundle_client_id=' + this.p_supportData.user_shop_bundle_client_id;
      }
      let data = {
        'subject': this.p_supportData.subject,
        'operation_name': this.p_supportData.operation_name,
        'operation_note': this.p_supportData.operation_note + '\n' + this.p_supportData.operation_note_additional,
      };
      UserSupportService.user_support_new(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.f_goCustomerService();
            this.p_supportData.show = false;
            if (this.f_userSupportList) {
              this.f_userSupportList();
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
  }
}
</script>
