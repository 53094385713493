import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  user_support_new (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/support/new?' + query;
    } else {
      route = 'v1/user/support/new';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_support_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/support/list?' + query;
    } else {
      route = 'v1/user/support/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_support_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/support/get?' + query;
    } else {
      route = 'v1/user/support/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_support_comment (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/support/comment?' + query;
    } else {
      route = 'v1/user/support/comment';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_support_terminate (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/support/terminate?' + query;
    } else {
      route = 'v1/user/support/terminate';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
};
